<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <section class="grid-view">
      <b-card
        v-for="(round, i) in rounds"
        :key="i"
        :title="round.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <b-card-body>
          <b-card-title
            v-if="round.users_organizationtable"
          >{{ round.users_organizationtable.title }}
            <b-badge
              :variant="round.programs_applicationscorestables.length ? 'success' : 'light-secondary'"
            >{{
              round.programs_applicationscorestables.length ? 'Score: ' + round.programs_applicationscorestables[0].direct_score : 'Pending'
            }}
            </b-badge>
          </b-card-title>

          <b-card-text>Website Link: {{
            round.users_organizationtable ? round.users_organizationtable.url : ''
          }}
          </b-card-text>

          <b-card-text />
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            :to="`/mentor-portal/programs/application/score/${$route.params.id}/${$route.params.sid}/${round.id}/${$route.params.pid}`"
            class="btn btn-cart btn-primary"
            v-text="round.programs_applicationscorestables.length ? 'View Score' : 'Review'"
          />
        </div>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardText, BCardTitle, BLink, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    // BCardSubTitle,
    BOverlay,
    BLink,
    BBadge,
  },
  data() {
    return {
      rounds: [],
      userOrgs: getUserData().id,
    }
  },
  apollo: {
    rounds: {
      query() {
        return gql`
          {
            programs_applicantstable(where: {program_id: {_eq: ${this.$route.params.id}}}) {
                id
                users_organizationtable {
                  title
                  url
                  logo
                }
                programs_applicationscorestables(where: {direct_score: {_is_null: false}, partner_id: {_eq: ${this.$route.params.pid} }}) {
                  direct_score
                }
              }
           }`
      },
      update: data => data.programs_applicantstable.sort((a, b) => a.programs_applicationscorestables.length - b.programs_applicationscorestables.length),
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
